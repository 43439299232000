<template>
  <div class="c_schedule">
    <div class="stu-module-header">
      <div class="stu-module-title">时间安排</div>
    </div>
    <div v-loading="loading" style="min-height: 100px">
      <!-- <el-descriptions
        class="descriptions"
        :column="1"
        size="medium"
        border
        v-if="items"
      >
        <el-descriptions-item label="批次">{{
          items.batchName
        }}</el-descriptions-item>
        <el-descriptions-item
          v-if="items.workStartTime || items.workEndTime"
          label="毕业大作业时间"
        >
          <span>{{ items.workStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.workEndTime || "" }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="items.projectStartTime || items.projectEndTime"
          label="毕业设计时间"
        >
          <span>{{ items.projectStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.projectEndTime || "" }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="items.questionStartTime || items.questionEndTime"
          label="开题时间"
        >
          <span>{{ items.questionStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.questionEndTime || "" }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="items.paperStartTime || items.paperEndTime"
          label="论文时间"
        >
          <span>{{ items.paperStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.paperEndTime || "" }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="免做申请时间">
          <span>{{ items.freeStartTime }}</span>
          <span>至</span>
          <span>{{ items.freeEndTime }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="items.writeStartTime || items.writeEndTime"
          label="撰写毕业大作业时间"
        >
          <span>{{ items.writeStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.writeEndTime || "" }}</span>
          >
        </el-descriptions-item>
        <el-descriptions-item label="成绩上报时间">
          <span>{{ items.scoreStartTime }}</span>
          <span>至</span>
          <span>{{ items.scoreStartTime }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="items.assessStartTime || items.assessEndTime"
          label="评价时间"
        >
          <span>{{ items.assessStartTime || "" }}</span>
          <span>至</span>
          <span>{{ items.assessEndTime || "" }}</span>
        </el-descriptions-item>
      </el-descriptions> -->

      <ul class="c_schedule_ul" v-if="items">
        <li>
          <p class="label">批次:</p>
          <p class="value">{{ items.batchName }}</p>
        </li>
        <li v-if="items.workStartTime || items.workEndTime">
          <p class="label">毕业大作业时间:</p>
          <p class="value">
            <span>{{ items.workStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.workEndTime || "" }}</span>
          </p>
        </li>
        <li v-if="items.projectStartTime || items.projectEndTime">
          <p class="label">毕业设计时间:</p>
          <p class="value">
            <span>{{ items.projectStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.projectEndTime || "" }}</span>
          </p>
        </li>
        <li v-if="items.questionStartTime || items.questionEndTime">
          <p class="label">开题时间:</p>
          <p class="value">
            <span>{{ items.questionStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.questionEndTime || "" }}</span>
          </p>
        </li>
        <li v-if="items.paperStartTime || items.paperEndTime">
          <p class="label">论文时间:</p>
          <p class="value">
            <span>{{ items.paperStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.paperEndTime || "" }}</span>
          </p>
        </li>
        <li>
          <p class="label">免做申请时间:</p>
          <p class="value">
            <span>{{ items.freeStartTime }}</span>
            <span>至</span>
            <span>{{ items.freeEndTime }}</span>
          </p>
        </li>
        <li v-if="items.writeStartTime || items.writeEndTime">
          <p class="label">撰写毕业大作业时间:</p>
          <p class="value">
            <span>{{ items.writeStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.writeEndTime || "" }}</span>
          </p>
        </li>
        <li>
          <p class="label">成绩上报时间:</p>
          <p class="value">
            <span>{{ items.scoreStartTime }}</span>
            <span>至</span>
            <span>{{ items.scoreStartTime }}</span>
          </p>
        </li>
        <li v-if="items.assessStartTime || items.assessEndTime">
          <p class="label">评价时间:</p>
          <p class="value">
            <span>{{ items.assessStartTime || "" }}</span>
            <span>至</span>
            <span>{{ items.assessEndTime || "" }}</span>
          </p>
        </li>
      </ul>
      <div v-else>{{ message }}</div>
    </div>
  </div>
</template>

<script>
import {
  QueryEduCourseBatchTime, // 毕业大作业
  QueryEduGraduateDesignBatchTime, // 毕业设计
} from "@/libs/api/topic";
import { COURSE_TYPE } from "@/tools/const";
export default {
  name: "c_schedule",
  data() {
    return {
      loading: false,
      items: null,
      message: null,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      const type = this.course.courseType;
      const ServiceItem =
        type === Number(COURSE_TYPE.C2)
          ? QueryEduCourseBatchTime
          : QueryEduGraduateDesignBatchTime;
      ServiceItem()
        .then((res) => {
          this.loading = false;
          this.items = res.data;
        })
        .catch((err) => {
          const { message } = err;
          this.loading = false;
          this.message = message || null;
        });
    },
  },
};
</script>

<style scoped lang="less">
.c_schedule {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 30px;
  }
  .c_schedule_ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-weight: 500;
      }
      .label {
        width: 160px;
      }
      .value {
        display: flex;
        align-items: center;
        margin-left: 20px;
        span {
          min-width: 120px;
          text-align: center;
        }
        span:nth-child(2) {
          min-width: unset;
          padding: 0 10px;
        }
        span:not(:nth-child(2)) {
          color: rgb(92, 91, 91);
        }
      }
    }
  }

  .my-label {
    border: 1px solid #000;
  }
  .descriptions {
    .el-descriptions-item__content {
      span:nth-child(2) {
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
}
</style>